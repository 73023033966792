// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7VspmgOUTA2kSFtZGwApFFIlirC6zfZ8",
  authDomain: "yvom2nd-8957e.firebaseapp.com",
  databaseURL: "https://yvom2nd-8957e-default-rtdb.firebaseio.com",
  projectId: "yvom2nd-8957e",
  storageBucket: "yvom2nd-8957e.appspot.com",
  messagingSenderId: "365422535021",
  appId: "1:365422535021:web:7b860447a2e6b9d9a2ba64",
  measurementId: "G-NHXJMZ4DYT"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };